<div class="container-fluid">
  <div class="row border border-secondary rounded p-3">
    <div class="col-12">
      <h3 class="text-center text-primary">Промоции</h3>
      <!--
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-sm-3 align-self-center text-center">
          <img class="img-responsive" [src]="promoUrl" alt="promo.png">
        </div>
        <div class="col-sm-9 pl-sm-0">
          <h4 class="text-center text-md-left">
            <u class="align-middle">Годишни Технически Прегледи</u>
          </h4>
          <h1 class="text-center text-danger">отстъпка: -15%</h1>
          <h5 class="text-justify">
            Всеки трети преглед в <strong class="text-primary">ГТП - АВТО ХОЛД</strong> е с <u>отстъпка: -15%</u>.
            Моля бъдете търпеливи.
            <h4>Очакваме Ви!</h4>
          </h5>
        </div>
      </div>
      -->
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-sm-3 align-self-center text-center">
          <img class="img-responsive" [src]="promoUrl" alt="promo.png">
        </div>
        <div class="col-sm-9 pl-sm-0">
          <h4 class="text-center text-md-left"><u>Годишни Технически Прегледи</u></h4>
          <h1 class="text-center text-danger">цена: 75 лева с ДДС</h1>
          <h5 class="text-justify">
            Всички клиенти на автосервиз <strong class="text-primary">АВТО ХОЛД</strong> могат да позват преференциални цени в пункта ни за <u>Годишни Технически Прегледи</u>.
            За да се възползвате попитайте приемчика на автосервиза.
            <h4>Очакваме Ви!</h4>
          </h5>
        </div>
      </div>
      <!--
      <div class="row border border-secondary rounded pt-3">
        <div class="col-sm-3 align-self-center text-center">
          <img class="img-responsive" [src]="promoUrl" alt="promo.png">
        </div>
        <div class="col-sm-9 pl-sm-0">
          <h4 class="text-center text-md-left">
            <u class="align-middle">Авто-монтьорски услуги - <strong class="text-primary">база Овчакупел</strong></u>
          </h4>
          <h1 class="text-center text-danger">отстъпка: -10%</h1>
          <h5 class="text-justify">
            Ако закупите всички части и консумативи необходими за ремонта на Вашия автомобил, ще ползвате <u>10% отстъпка</u>
            от стойността на ремонта, с изключение на закупените части и консумативи от официален вносител.
            <h4>Очакваме Ви!</h4>
          </h5>
        </div>
      </div>
      -->
    </div>
  </div>
</div>