<div class="container-fluid">
  <div class="row justify-content-center">
    <div class="col px-0">
      <div class="row justify-content-center">
        <div class="col-sm-6 pb-3 pr-md-2"> <!--GTP-->
          <div class="card">
            <h4 class="card-header text-center">{{gtp.title}}</h4>
            <div class="card-body">
              <div class="row">
                <div class="col-12 align-self-center pb-3">
                  <agm-map id="gtp_map" [latitude]="gtp.lat" [longitude]="gtp.lng" [zoom]="gtp.zoom">
                    <agm-marker [latitude]="gtp.lat" [longitude]="gtp.lng">
                      <agm-snazzy-info-window [maxWidth]="200" [closeWhenOthersOpen]="true">
                        <ng-template>
                          <div [innerHTML]="gtp.infowindow"></div>
                        </ng-template>
                      </agm-snazzy-info-window>
                    </agm-marker>
                  </agm-map>
                </div>
                <div class="col-12 align-self-center">
                  <div class="row">
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-map-marker-alt fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>гр. София<br>бул. "Шипченски проход" №77</h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-mobile fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="tel:+359893000047">+359893000047</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-envelope fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="mailto:gtp@autohold.eu">gtp@autohold.eu</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-clock fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>понеделник-петък от 09:00 до 19:00 часа</h5>
                      <h5>събота от 09:00 до 16:00 часа</h5>
                      <h5>неделя от 09:00 до 16:00 часа</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">       
        <div class="col-sm-6 pb-3 pl-md-2"> <!--AH1-->
          <div class="card">
            <h4 class="card-header text-center">{{ah1.title}}</h4>
            <div class="card-body">
              <div class="row">
                <div class="col-12 align-self-center pb-3">
                  <agm-map [latitude]="ah1.lat" [longitude]="ah1.lng" [zoom]="ah1.zoom">
                    <agm-marker [latitude]="ah1.lat" [longitude]="ah1.lng">
                      <agm-snazzy-info-window [maxWidth]="200" [closeWhenOthersOpen]="true">
                        <ng-template>
                          <div [innerHTML]="ah1.infowindow"></div>
                        </ng-template>
                      </agm-snazzy-info-window>
                    </agm-marker>
                  </agm-map>
                </div>
                <div class="col-12 align-self-center">
                  <div class="row">
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-map-marker-alt fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>гр. София<br>бул. "Овча купел" №77</h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-mobile fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="tel:+359899187842">+359899187842</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-envelope fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="mailto:service_01@autohold.eu">service_01@autohold.eu</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-clock fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>понеделник-петък от 09:00 до 18:30 часа</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--AH2-->
        <!--
        <div class="col-sm-6 pb-3 pb-md-0 pr-md-2"> 
          <div class="card">
            <h4 class="card-header text-center">{{ah2.title}}</h4>
            <div class="card-body">
              <div class="row">
                <div class="col-12 align-self-center pb-3">
                  <agm-map [latitude]="ah2.lat" [longitude]="ah2.lng" [zoom]="ah2.zoom">
                    <agm-marker [latitude]="ah2.lat" [longitude]="ah2.lng">
                      <agm-snazzy-info-window [maxWidth]="200" [closeWhenOthersOpen]="true">
                        <ng-template>
                          <div [innerHTML]="ah2.infowindow"></div>
                        </ng-template>
                      </agm-snazzy-info-window>
                    </agm-marker>
                  </agm-map>
                </div>
                <div class="col-12 align-self-center">
                  <div class="row">
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-map-marker-alt fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>гр. София<br>ул. "Тинтява" №100</h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-mobile fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="tel:+359898672531">+359898672531</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-envelope fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="mailto:service_02@autohold.eu">service_02@autohold.eu</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-clock fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>понеделник-петък от 09:00 до 18:30 часа</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        -->  

        <div class="col-sm-6 pl-md-2"> <!--AH3-->
          <div class="card">
            <h4 class="card-header text-center">{{ah3.title}}</h4>
            <div class="card-body">
              <div class="row">
                <div class="col-12 align-self-center pb-3">
                  <agm-map [latitude]="ah3.lat" [longitude]="ah3.lng" [zoom]="ah3.zoom">
                    <agm-marker [latitude]="ah3.lat" [longitude]="ah3.lng">
                      <agm-snazzy-info-window [maxWidth]="200" [closeWhenOthersOpen]="true">
                        <ng-template>
                          <div [innerHTML]="ah3.infowindow"></div>
                        </ng-template>
                      </agm-snazzy-info-window>
                    </agm-marker>
                  </agm-map>
                </div>
                <div class="col-12 align-self-center">
                  <div class="row">
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-map-marker-alt fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>гр. София<br>ул. "Първа Българска Армия" №14</h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-mobile fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="tel:+359895602896">+359895602896</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-envelope fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5><a href="mailto:service_03@autohold.eu">service_03@autohold.eu</a></h5>
                    </div>
                    <div class="col-sm-3 align-self-center text-center">
                      <i class="fas fa-clock fa-2x" style="color: steelblue"  aria-hidden="true"></i>
                    </div>
                    <div class="col-sm-9 align-self-center text-center text-md-left">
                      <h5>понеделник-петък от 09:00 до 18:30 часа</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>