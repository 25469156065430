<div class="container-fluid">
  <div class="row border border-secondary rounded px-3 pt-3">
    <div class="col-12">
      <h3 class="text-center text-primary">Автосервиз</h3>
        <div class="row">
          <div class="col-12 text-justify px-0">
            <h5>
              <strong class="text-primary">АВТО ХОЛД</strong> е сертифицирана по ISO 9001:2008 и разполага с три автосервизни бази в гр. София.
              <br>
              Изборът на автосервиз винаги е трудна задача. При нас ще получите качество, персонално внимание и изпълнение в срок.
              Ние разполагаме с необходимото оборудване, знания и умения, за да извършим желаната от Вас услуга. <strong class="text-primary">АВТО ХОЛД</strong> са доверени автосервизи на повечето застрахователни компании.
              Какво означава да си доверен сервиз - преди всичко това е <u>гаранция за качество</u>. Ako имате нужда от качествено отремонтиране на щети, при настъпило застрахователно събитие, поискате възлагателно писмо за
              нашия доверен автосервиз и ни се доверете с поправката на Вашия автомобил. Когато уведомите своя застраховател в срок, предоставите необходимите документи, минете преглед от експертите на
              застрахователната компания, Вие имате право да получите обезщетение за заведените щети, които можете да отремонтирате във всеки автосервиз. Един от най-добрите избори, които можете да направите е да поискате
              възлагателно писмо за отремонтиране на щети в доверен автосервиз на застрахователя. С него Вие имате право да отремонтирате щетите за сметка на застрахователя в нашия автосервиз -
              ние ще отремонтираме бързо и качествено щетите по Вашия автомобил и ще потърсим своето възнаграждение от застрахователя.
              Независимо от марката или възрастта на Вашия автомобил, ние изпълняваме отремонтирането на щети в срок и Ви връщаме автомобила в изрядно състояние.
              <br>
              В нашите автосервизи може да извършите ремонт на Вашия автомобил и без <u>възлагателно писмо</u> на конкурентни цени.
              <br>
              В стремежа си да предоставим цялостно обслужване на нашите Клиенти през 2017 година ние започнахме да предлахаме авто-монтьорски услуги.
              Също така отвори врати и нашият пункт за <span class="text-primary">Годишни Технически Прегледи</span>. Вижте предлаганите от нас <a class="text-primary"  [routerLink]="[ '..','services']">услуги</a>.
              <br>
              Нашите клиенти могат да се възползват от нашите преференциални цени и атрактивни <a class="text-primary" [routerLink]="[ '..','promo']">отстъпки</a>.
              <br>
              <h4>Очакваме Ви!</h4>
            </h5>
          </div>
        </div>
    </div>
  </div>
</div>