<nav class="navbar navbar-expand-lg navbar-dark bg-primary">
  <a (click)="toggleCollapse()" class="navbar-brand" [routerLink]="['promo']">
    <img src="favicon.ico" width="30" height="30" class="d-inline-block align-top" alt="">
    АВТО ХОЛД
  </a>
  <button (click)="toggleCollapse(true)" class="navbar-toggler" type="button" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    <span [hidden]="collapse=='open'" class="fa fa-bars fa-lg"></span>
    <span [hidden]="collapse=='closed'" class="fa fa-times fa-lg"></span>
  </button>
  
  <!--<div class="collapse navbar-collapse show"  [@collapse]="collapse">-->
  <div class="collapse navbar-collapse" [class.show]="show">
    <ul class="nav navbar-nav mr-auto"> <!--nav-fill w-100 align-items-start--> 
      <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['home']">Начало</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['news']">Новини</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['promo']">Промоции</a>
      </li>
     <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['services']">Услуги</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['gtp']">ГТП</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['autoservice']">Автосервиз</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['career']">Кариери</a>
      </li>
      <li class="nav-item" routerLinkActive="active">
        <a (click)="toggleCollapse()" class="nav-link"  [routerLink]="['contacts']">Контакти</a>
      </li>
    </ul>
  </div>
</nav>
