import { Component, OnInit, ViewChild, HostListener } from '@angular/core';

import { AgmMap } from '@agm/core';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css']
})

export class ContactsComponent implements OnInit {
  @ViewChild(AgmMap) public agmMap!: AgmMap;
  @HostListener('window:resize')
  onWindowResize() {
    this.agmMap.triggerResize(); // ЗАЩО НЕ РАБОТИ ???????????
    console.log("window:resize")
  }
  
  gtp: AGMlocation;
  ah1: AGMlocation;
  ah2: AGMlocation;
  ah3: AGMlocation;
  
  constructor() { 
    this.gtp = {
      title: 'Годишни Технически Прегледи',
      infowindow: 'ГТП Авто Холд<br /><br />цена: 42 лева',
      lat: 42.675256,
      lng: 23.375735,
      zoom: 17
    };
    this.ah1 = {
      title: 'Авто Холд - база Овчакупел',
      infowindow: 'Авто Холд<br />база Овчакупел<br />',
      lat: 42.684090,
      lng: 23.272493,
      zoom: 17
    }
    this.ah2 = {
      title: 'Авто Холд - база Тинтява',
      infowindow: 'Авто Холд<br />база Тинтява<br />',
      lat: 42.667178,
      lng: 23.350262,
      zoom: 17
    }
    this.ah3 = {
      title: 'Авто Холд - база Орландовци',
      infowindow: 'Авто Холд<br />база Орландовци<br />',
      lat: 42.724040,
      lng: 23.322340,
      zoom: 17
    }
  }

  ngOnInit() {
  }
}

interface AGMlocation {
  title: string;
  infowindow: string;
  lat: number;
  lng: number;
  zoom: number;
}

