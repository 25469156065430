<div class="container-fluid">
  <div class="row border border-secondary rounded p-3">
    <div class="col-12">
      <h3 class="text-center text-primary">Кариера</h3>
      <h3 class="text-center">Станете част от нашия екип</h3>
      <div class="row">
        <div class="col-12 text-justify px-0">
          <h5>
            Непрекъснато разширяваме дейността си и откриваме нови работни позиции. В тази връзка търсим мотивирани хора, специалисти. Ние изискваме:
            <ul class="mb-0">
              <li>Предишен опит на подобна позиция;</li>
              <li>Желание за дългосрочна работа в екип;</li>
              <li>Енергично и отговорно отношение към работата.</li>
            </ul>
            Ако имате желание да се присъедините към нашия екип, изпратете CV и снимка на e-mail: <a href="mailto:office@autohold.eu">office@autohold.eu</a>.
            Ако кандидатурата Ви е подходяща за желаната от Вас позиция ще Ви поканим за събеседване в офиса на автосервиза.
            <br><br>
            Търсим специалисти за следните позиции:
          </h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Годишни технически прегледи</u></h4>
          <h5>
            <ul class="mb-0">
              <li>Председател на комисия;</li>
              <li>Член на комисия.</li>
            </ul>
          </h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Авто-монтьор</u></h4>
          <h5>
            <ul class="mb-0">
              <li>Монтьори и механици на лекотоварни автомобили;</li>
              <li>Компютърна диагностика.</li>
            </ul>
          </h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3">
        <div class="col-12 text-justify">
          <h4><u>Автосервиз - авто-тенекидия и авто-бояджия</u></h4>
          <h5>
            <ul class="mb-0">
              <li>Приемчик в автосервиз;</li>
              <li>Помощник-приемчик в автосервиз;</li>
              <li>Авто-тенекеджия;</li>
              <li>Авто-бояджия;</li>
              <li>Подготовка боядисване.</li>
            </ul>
          </h5>
        </div>
      </div>
    </div>
  </div>
</div>