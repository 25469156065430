<div class="container-fluid">
  <div class="row mb-3 px-0">
    <div class="col-12 px-0">
      <carousel>
        <slide>
          <img class="img-responsive rounded" src="assets/images/gtp.jpg" alt="1 slide" style="display: block; width: 100%;">
          <div class="carousel-caption d-none d-md-block">
            <h1>Авто Холд</h1>
            <h2><u>Годишни Технически Прегледи</u></h2>
          </div>
        </slide>
        <slide>
          <img class="img-responsive rounded" src="assets/images/service-01.jpg" alt="2 slide" style="display: block; width: 100%;">
          <div class="carousel-caption d-none d-md-block">
            <h1>Авто Холд</h1>
            <h2><u>база Овчакупел</u></h2>
          </div>
        </slide>
        <!--
        <slide>
          <img class="img-responsive rounded" src="assets/images/service-02.jpg" alt="3 slide" style="display: block; width: 100%;">
          <div class="carousel-caption d-none d-md-block">
            <h1>Авто Холд</h1>
            <h2><u>база Тинтява</u></h2>
          </div>
        </slide>
        -->
        <slide>
          <img class="img-responsive rounded" src="assets/images/service-03.jpg" alt="4 slide" style="display: block; width: 100%;">
          <div class="carousel-caption d-none d-md-block">
            <h1>Авто Холд</h1>
            <h2><u>база Орландовци</u></h2>
          </div>
        </slide>
      </carousel>
    </div>
  </div>
  
  <div class="row border border-secondary rounded py-3">
    <div class="col-12 text-justify">
      <h3 class="text-center text-primary">Авто Холд</h3>
      <h5>
        Фирма <strong class="text-primary">АВТО ХОЛД</strong> е специализирана във всички видове авто-тенекеджийски и авто-бояджийски услуги. Екипът на фирмата се състои от специалисти с богат опит.
        <strong class="text-primary">АВТО ХОЛД</strong> разполага с три автосервизни бази в гр. София. Общата закрита производствена площ е около 2400 кв.м. и 40 работни поста. Автосервиза има възможност за
        възстановяване на тежко катастрофирали автомобили. Благодарение на натрупания дългогодишен опит, ние можем да предложим на своите Клиенти, комлексни и
        гъвкави решения, съобразени с конкретните нужди и изисквания.
      </h5>
      <h5>
        В своята работа и взаимоотношения с Клиентите предлагаме най-високите световни стандарти. Партньорите ни са водещи фирми от бранша в световен мащаб и
        заемат значително място на пазара. Фирмата е сертифицирана по ISO 9001:2008. Специалистите ни винаги дават максимума от себе си, за да надминат
        очакванията Ви. <strong class="text-primary">АВТО ХОЛД</strong> е мястото, където можете да оставите своя автомобил и да продължите спокойно с ежедневието си до следващата ни среща.
      </h5>
      <h5>
        Ние сме доверен сервиз на водещите застрахователни компании в страната. <strong class="text-primary">АВТО ХОЛД</strong> е идеалният избор за всеки, който желае да спести време и излишни
        притеснения. Сервизът може да се похвали с добра локация, три сервизни бази в гр. София. Доверявайки се на <strong class="text-primary">АВТО ХОЛД</strong>, Вие избирате доверен партньор,
        който има дългогодишен опит с множество застрахователни компании. Нашите услуги на доверен сервиз на застрахователните компании са всеизвестни с висококачествено
        изпълнение в срок.
      </h5>
      <h5 class="mb-0">
        В стремежа си <strong class="text-primary">АВТО ХОЛД</strong> да задоволи всички потредбости на Клиентите си от началото на 2017 година започна да предлага авто-монтьорски услуги в база
        Овчакупел. През средата на 2017 година отвори врати пункта ни за Годишни Технически Прегледи, който е оборудван с последно поколение уреди.
      </h5>
    </div>
  </div>
</div>