<div class="container-fluid">
  <div class="row border border-secondary rounded p-3">
    <div class="col-12">
      <h3 class="text-center text-primary">Услуги</h3>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Авто-монтьорски услуги</u></h4>
          <h5>
            В <strong class="text-primary">база Овчакупел</strong>, с адрес гр. София бул. "Овча купел" №77, всякакви авто-монтьорски услуги на конкурентни цени.
            Ето някои от тях:
          </h5>
          <ul class="h5 mb-2">
            <li>Смяна на масло и филтри;</li>
            <li>Ремонти на ходовата част и спирачките, смяна на накладки, носачи, шарнири, тампони, карета и др.;</li>
            <li>Смяна на ремъци и ролки, вериги и обтегачи;</li>
            <li>Ремонт на двигател и всички видове горивни системи;</li>
            <li>Диагностика по най-съвременна технология с апаратура от ново поколение;</li>
            <li>Проверка и обслужване на всички видове климатични и охладителни системи;</li>
            <li>Смяна, монтаж, демонтаж и баланс на гуми;</li>
            <li>Ремонт отделителна система, смяна на ауспуси.</li>
          </ul>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Годишни технически прегледи</u></h4>
          <h5>
            <strong class="text-primary">ГТП - АВТО ХОЛД</strong> се намира на изключително комуникативно място с адрес - гр. София бул. "Шипченски проход" №77.
            Тук извършваме задължителните периодичните прегледи за проверка на техническата изправност на ППС. Времетраенето на техническия преглед е 20-30 минути.
            Извършваме <strong class="text-primary">ГТП</strong> на следните превозни средства:
          </h5>
          <ul class="h5 mb-2">
            <li>Леки автомобили;</li>
            <li>Товарни автомобили до 3.5t;</li>
            <li>Ремаркета до 0.75t;</li>
            <li>Автомобили 4x4;</li>
            <li><span class="text-danger">Мотоциклети.</span></li>
          </ul>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Доверен автосервиз на водещи застрахователи</u></h4>
          <h5>
            <strong class="text-primary">АВТО ХОЛД</strong>, разполага с три сервизни бази в гр. София. Автосервиза има възможност за възстановяване на тежко катастрофирали автомобили.
            Имаме договор за доверен автосервиз със следните застрахователи:
          </h5>
          <ul class="h5 mb-2">
            <li>ЗАД "БУЛСТРАД ВИЕНА ИНШУРЪНС ГРУП" АД;</li>
            <li>"ДЗИ - ОБЩО ЗАСТРАХОВАНЕ" ЕАД;</li>
            <li>ЗК "ЛЕВ ИНС" АД</li>
            <li>ЗАД "АСЕТ ИНШУРЪНС" АД</li>
            <li>ЗК "Олимпик - клон България" КЧТ</li>
            <li>"ДЖЕНЕРАЛИ ЗАСТРАХОВАНЕ" АД</li>
            <li>ЗАД "АЛИАНЦ БЪЛГАРИЯ" АД</li>
            <li>ЗК "УНИКА" АД</li>
            <li>ЗК "Надежда" АД</li>
            <li>ЗАД "ДаллБогг Живот и Здраве" АД</li>
          </ul>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3">
        <div class="col-12 text-justify">
          <h4><u>Авто-тенекеджийски и Авто-бояджийски услуги</u></h4>
          <h5>
            С услугите на автосервиз <strong class="text-primary">АВТО ХОЛД</strong>, можете да бъдете сигурни, че повредите от всякакъв тип ще бъдат възстановени.
          </h5>
          <ul class="h5 mb-2">
            <li>Всички авто-тенекеджийски, включително и възстановяване на тежко катастрофирали автомобили;</li>
            <li>Боядисване - цялостно или частично;</li>
            <li>Пастиране – цялостно и частично;</li>
            <li>Полиране на фарове.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>