<div class="container-fluid">
  <div class="row border border-secondary rounded p-3">
    <div class="col-12">
      <h3 class="text-center text-primary">Новини</h3>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-sm-2 align-self-center text-center">
          <img class="img-responsive" [src]="newsUrl" alt="news.png">
        </div>
        <div class="col-sm-10">
          <h4 class="text-center text-md-left">
            <u class="align-middle">Годишни Технически Прегледи - ново работно време</u>
          </h4>
          <h5 class="text-justify">
              С цел подобряване качеството на обслужване и по-голямо удобство за клиентите ни пункта за ГТП на <strong class="text-primary">АВТО ХОЛД</strong> от Март 2019г. е с променено работно време.
              <br><br>
              <h5>понеделник-петък от 09:00 до 19:00 часа</h5>
              <h5>събота от 09:00 до 16:00 часа</h5>
              <h5>неделя от 09:00 до 16:00 часа</h5>
              <h4 class="text-danger">Очакваме Ви!</h4>
          </h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-sm-2 align-self-center text-center">
          <img class="img-responsive" [src]="newsUrl" alt="news.png">
        </div>
        <div class="col-sm-10">
          <h4 class="text-center text-md-left">
            <u class="align-middle">Годишни Технически Прегледи</u>
          </h4>
          <h5 class="text-justify">
              Всички клиенти на автосервиз <strong class="text-primary">АВТО ХОЛД</strong> могат да позват преференциални цени в пункта ни за <u>Годишни Технически Прегледи</u>.
              За да се възползвате попитайте приемчика на автосервиза.
            <h4 class="text-danger">Очакваме Ви!</h4>
          </h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-sm-2 align-self-center text-center">
          <img class="img-responsive" [src]="newsUrl" alt="news.png">
        </div>
        <div class="col-sm-10">
          <h4 class="text-center text-md-left">
            <u class="align-middle">Годишни Технически Прегледи</u>
          </h4>
          <h5 class="text-justify">
              В средата на 2017 година отвори врати пункта ни за <strong class="text-primary">Годишни Технически Прегледи</strong>. Той се намира на изключително комуникативно място
              с адрес - гр. София бул. "Шипченски проход" №77. Пункта има модерна визия и е оборудван с техника от най-висок клас. Времето необходимо за извършване на техническия преглед ще мине неусетно в приятна обстановка.
          </h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-sm-2 align-self-center text-center">
          <img class="img-responsive" [src]="newsUrl" alt="news.png">
        </div>
        <div class="col-sm-10">
          <h4 class="text-center text-md-left">
            <u class="align-middle">ЗАД "АСЕТ ИНШУРЪНС" АД</u>
          </h4>
          <h5 class="text-justify">
              През март 2017 година в <strong class="text-primary">АВТО ХОЛД</strong> сключи договор за <u>доверен сервиз</u> с бързо развиващата се застрахователна компания Асет Иншурънс ЗАД.
              Очакваме нашите редовни клиенти както и тези, които все още не са ни посетили.
          </h5>
        </div>
      </div>
      <!--
      <div class="row border border-secondary rounded pt-3">
        <div class="col-sm-2 align-self-center text-center">
          <img class="img-responsive" [src]="newsUrl" alt="news.png">
        </div>
        <div class="col-sm-10">
          <h4 class="text-center text-md-left">
            <u class="align-middle">Авто-монтьорски услуги</u>
          </h4>
          <h5 class="text-justify">
              От началото на 2017 година в <strong class="text-primary">сервизна база Овчакупел</strong> започнахме да предлагаме всички видове авто-монтьорски услуги.
              Стремежа ни е да извършваме бързи и качествени ремонти на Вашия автомобил на конкурентни цени. Влаганите резервни части и консумативи са
              с високо качество от световни марки. Също така предлагаме демонтаж и монтаж на гуми, както и проверка и ремонт на Вашия климатик.
              Можем да направим прецизна компютърна диагностика на Ваши автомобил.
          </h5>
        </div>
      </div>
      -->
    </div>
  </div>
</div>