<div class="container-fluid">
  <div class="row border border-secondary rounded p-3">
    <div class="col-12">
      <h3 class="text-center text-primary">Годишни Технически Прегледи</h3>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>ГТП - Авто Холд</u></h4>
          <h6>
            На основание чл.148, ал.(2) от Закона за движение по пътищата и чл.20, ал.(1) от Наредба №Н-32 за периодичните прегледи за проверка на
            техническата изправност на ППС, МТ издава Разрешение №1609 за извършване на периодични прегледи за проверка на техническата изправност на ППС:
          </h6>
          <ul class="mb-2">
            <li>категория M1 - ППС с не повече от 8 места за сядане, без мястото за сядане на водача;</li>
            <li>категория M1G - ППС с повишена проходимост с не повече от 8 места за сядане, без мястото за сядане на водача;</li>
            <li>категория N1 - ППС с технически допустима максимална маса не повече от 3.5t;</li>
            <li>категория N1G - ППС с повишена проходимост с технически допустима максимална маса не повече от 3.5t;</li>
            <li>категория O1 - ремаркета с технически допустима максимална маса не повече от 0.75t;</li>
            <li><span class="text-danger">категория A - мотоциклети.</span></li>
          </ul>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Работно време</u></h4>
          <h5>понеделник-петък от 09:00 до 19:00 часа</h5>
          <h5>събота от 09:00 до 18:00 часа</h5>
          <h5>неделя от 09:00 до 16:00 часа</h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Цена</u></h4>
          <h5>
            Цената за всички ППС е 75 лева с включен ДДС.
          </h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Необходими документи</u></h4>
          <h6>
            Съгласно чл.30, ал.(1) от Наредба №Н-32, при извършване на периодичен технически преглед на ППС се представят следните документи:
          </h6>
          <ul>
            <li>Свидетелство за регистрация на ППС – части I и II в оригинал, като се допуска ясно и четливо копие на част I на свидетелството за регистрация;</li>
            <li>Полица от валидна застраховка гражданска отговорност;</li>
            <li>Документ за платен данък върху превозните средства, изискуем към деня на извършването на периодичния преглед на ППС съгласно чл.60, ал.(6) от Закона за местните данъци и такси, издаден или заверен от съответната община, или документ, че са освободени от данък съгласно чл.58 от Закона за местните данъци и такси;</li>
            <li>Документ за самоличност на лицето, което представя ППС на преглед.</li>
          </ul>
          <h5>Препоръчително е при представяне за технически преглед ППС да бъде измито и почистено.</h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3 mb-3">
        <div class="col-12 text-justify">
          <h4><u>Период и валидност на ГТП</u></h4>
          <h6>
            Съгласно чл.147, ал.(3) от Закон за движение по пътищата ППС подлежат на технически преглед, както следва:
          </h6>
          <ul>
            <li>Леките автомобили трябва да преминават ГТП веднъж в годината;</li>
            <li>Новите автомобили трябва да преминават за първи път на ГТП на третата година, като вторият преглед се извършва две години след първия ( след петата година се явяват ежегодно);</li>
            <li>Таксиметровите автомобили трябва да преминават ГТП през 6 месеца;</li>
            <li>Товарните автомобили на възраст до 10 години трябва да преминават ГТП веднъж в годината;</li>
            <li>Товарните автомобили на възраст над 10 години трябва да преминават ГТП през 6 месеца;</li>
            <li>Ремаркетата с допустима максимална маса не повече от 0.75t трябва да преминават ГТП веднъж на всеки две години.</li>
          </ul>
          <h5>ГТП е валиден до датата написана в талона за преглед! Препоръчително е да не чакате последният момент.</h5>
        </div>
      </div>
      <div class="row border border-secondary rounded pt-3">
        <div class="col-12 text-justify">
          <h4><u>Какви са рисковете при шофиране с изтекъл ГТП?</u></h4>
          <h6>
            Ако шофирате с изтекъл ГТП, служителите на КАТ могат да ви глобят с фиш на стойност 50 лв. или да ви напишат акт, тъй като се приема, че карате автомобил, който е технически неизправен.
          </h6>
          <h6>
            При възникване на инцидент могат да се появят проблеми със застрахователи. Може да ви се откаже изплащане на обезщетение по застраховка „Каско“, както и по „Гражданска отговорност“.
          </h6>
        </div>
      </div>
    </div>
  </div>
</div>